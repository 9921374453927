import React from "react";
import { dsnCN } from "../../../hooks/helper";

function MenuContent({ className }) {
  const socialData = [
    {
      link: "https://www.facebook.com/profile.php?id=61556377243005&mibextid=LQQJ4d",
      name: "Facebook.",
    },
    {
      link: "https://x.com/corp_alas?s=21",
      name: "X.",
    },
    {
      link: "https://www.instagram.com/corp_alas?igsh=bnhwbWh3NGZsYTkw&utm_source=qr",
      name: "Instagram.",
    },
    {
      link: "https://www.linkedin.com/company/alas-corp/",
      name: "Linkedin.",
    },
    {
      link: "https://www.tiktok.com/@corp_alas?lang=es",
      name: "Tiktok.",
    },
  ];
  return (
    <div
      className={dsnCN(
        "container-content  d-flex flex-column justify-content-center",
        className
      )}
    >
      <div className="nav__info">
        <div className="nav-content">
          <h5 className="sm-title-block mb-10">Sede</h5>
          <p>
            CARRERA 24 # 47 - 60 <br /> Antioquia, Colombia
          </p>
        </div>
        <div className="nav-content mt-30">
          <h5 className="sm-title-block mb-10">Contacto</h5>
          <p className="links over-hidden mb-1">
            <a
              className="link-hover"
              href="tel:+573242758775"
              data-hover-text="+57 3242758775"
            >
              +57 324 2758775
            </a>
          </p>
          <p className="links over-hidden">
            <a
              className="link-hover"
              href="mailto:info@alascorporation.com"
              data-hover-text="info@alascorporation.com"
            >
              info@alascorporation.com
            </a>
          </p>
        </div>
      </div>
      <div className="nav-social nav-content mt-30">
        <div className="nav-social-inner p-relative">
          <h5 className="sm-title-block mb-10">Siguenos</h5>
          <ul>
            {socialData.map((item, index) => (
              <li key={index}>
                <a
                  href={item.link}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  {item.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default MenuContent;
